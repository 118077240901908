.terminal-window {
  width: 100%;
  max-width: 800px;
  background-color: #1e1e1e;
  color: white;
  border-radius: 10px;
  overflow: hidden;
  filter: url(#noise);
  animation: move-away 4s ease-in cubic-bezier(0.39, 0.575, 0.565, 1) forwards;
}

.terminal-header {
  padding: 10px;
  background-color: #292929;
  align-items: center;
  display: flex;
}

.terminal-header .dots {
  font-size: 30px;
  margin-right: 10px;
}

/* Three dots styling */
.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dot.red {
  background-color: #ff5f56;
}

.dot.yellow {
  background-color: #ffbd2e;
}

.dot.green {
  background-color: #27c93f;
}

.title {
  margin-left: auto;
}

.terminal-body {
  padding: 10px;
  font-size: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-bottom: 0;
  overflow: hidden;
}

.terminal-body pre {
  color: #00ff00;
  margin-top: 0;
}

.noise {
  width: 0;
  height: 0;
}

@keyframes move-away {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateY(-300px);
    opacity: 0;
  }
}