.landingpage {
    position: relative;
    background-color: black;
    height: 100vh;
    width: 120vw;
    overflow: hidden;
}

.darkerDiv {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 5;
    animation: darken 8s ease-out forwards;
}

@keyframes darken {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }

    60% {
        background-color: rgba(0, 0, 0, 0);
    }

    100% {
        background-color: rgba(0, 0, 0, 1);
    }

}

.terminal {
    top: 37%;
    position: absolute;
    width: 60vw;
    left: 50%;
    height: auto;
    transform: translateX(-50%);
    z-index: 2;
}

@media only screen and (max-width: 1000px) {
    .terminal {
        top: 27%;
        width: calc(100vw - 20px);
        left: 0;
        right: 0;
        transform: none;
        z-index: 2;
    }

    .landingpage {
        position: relative;
        background-color: black;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }
}

.blur-layer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(6px);
    z-index: 1;
}

.iframe {
    position: absolute;
    z-index: 0;
    overflow: hidden;
}