.actuallandingpage {
    position: relative;
    background-color: black;
    width: 100vw; 
    height: 100vh; 
    overflow: hidden; 
}

.test {
    color: white;
    width: 100%; 
    height: 100%; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
}
